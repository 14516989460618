@tailwind base;
@tailwind components;
@tailwind utilities;

body{
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}
.poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.pagination {
    @apply items-center flex
}

.pagination>li>a,
.pagination>li>span {
    @apply relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 cursor-pointer bg-black bg-opacity-10
}

.pagination>li:nth-child(2n + 1)>a,
.pagination>li:nth-child(2n + 1)>span {
    @apply bg-opacity-5
}

/* .pagination>li.active>a {
    @apply bg-shine text-white
} */



/* Style the active class (and buttons on mouse-over) */
/* .pagination>li>a:hover {
    @apply bg-shine text-white
} */

.pagination>li:first-child>a,
.pagination>li:first-child>span {
    @apply rounded-l block
}

.pagination>li:last-child>a,
.pagination>li:last-child>span {
    @apply rounded-r block mr-0
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}



/* Pagination container */
.pagination {
    display: flex;
    justify-content: end;
    list-style: none;
    padding: 0;
    margin-top: 20px;
  }
  
  /* Individual page link */
  .page-cls {
    display: inline-block;
    margin-right: 5px;
    padding: 5px 10px;
    /* border: 1px solid red; */
    border-radius: 3px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  
    /* Style for page link text */
    color: #333;
    text-decoration: none;
  }
  
  /* Active page link */
  .page-cls.active {
    background-color: #b577ac;
    color: #fff;
  }
  
  /* Previous and Next button styles */
  .pagination > li {
    display: inline-block;
    margin-right: 5px;
  }
  
  /* Previous and Next button link styles */
  .pagination > li > a {
    display: inline-block;
    padding: 5px 10px;
    border: 1px solid #ddd;
    border-radius: 3px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    color: black;
    text-decoration: none;
  }
  
  /* Styling for active page link of Previous and Next button */
  .pagination > li > a.active {
    background-color: #b577ac;
    color: #fff;
  }
  